@import "./common.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    outline: none;
    padding: 0;
    box-sizing: border-box;
}

@layer base {
    * {
        scrollbar-width: thin;
        scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
    }

    *::-webkit-scrollbar {
        width: 6px;
        height: 5px;
    }

    *::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(134, 134, 134, 0.5);
        border-radius: 5px;
        border: transparent;
    }
}

input {
    appearance: none;
    outline: none;
    border: none;
}